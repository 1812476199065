import { apiGateway } from "../../common/apiURLs";
import {
  get,
  post,
  uploadFile,
  downloadFile,
  getReturnError,
  uploadFileNoReturn,
  get3rdParty,
  carletonPut,
  carletonPost,
  postWithCustMesg,
} from "../../common/commonApi";
import {
  dealerCookie,
  fedExCookie,
  loanCookie,
  taxonomyCookie,
} from "../../common/csrfService";
import {
  contractTypes,
  eSignStatusCompInPrgEnum,
  enumContentTypes,
  formsListQueryCount,
} from "../../common/helperContainer";
import { DataSubmittedSuccessfullyText } from "../../common/messageContainer";

export const getAllLoanApps = apiGateway + `/api/loanapp/allloanapps`;

export const dummy = async () => {
  const queryUrl = `/api/loanapp/allloanapps`;
  return await post(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getloanAppDetails = async (appNo: any) => {
  const queryUrl = `/api/loanapp/loanapps/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const getNetPrdsDetails = async (id: any) => {
  const queryUrl = `/api/loanapp/netproceeds/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getFrmslnkDetails = async (dealerName: any) => {
  var queryUrl = `/api/dealer/DealerForms?Page=${1}&Limit=${formsListQueryCount}&DealerNames=${dealerName}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const exportToExcel = async (params: any, fileName: string) => {
  var queryUrl = `/api/loanapp/allloanreport?${params}`;
  return await downloadFile(apiGateway, queryUrl, "", fileName, loanCookie);
};

export const getFormsDownload = async (fileName: any) => {
  const queryUrl = `/api/dealer/DealerForms/Download/${fileName}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const getStipsDetails = async (id: any) => {
  const queryUrl = `/api/loanapp/Stipulation/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getDealerByClientDlrId = async (dealerId: any) => {
  const queryUrl = `/api/dealer/dealer/details/${dealerId}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const getDealerByPrimaryDlrId = async (dealerId: any) => {
  const queryUrl = `/api/dealer/dealer/primarydetails/${dealerId}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const uploadFiles = async (model: any) => {
  const queryUrl = "/api/loanapp/SaveFiles";
  return await uploadFile(apiGateway, queryUrl, model, loanCookie);
};

export const createShipment = async (model: any) => {
  const queryUrl = `/api/fedEx/createShipment`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    true,
    fedExCookie
  );
};

export const getFedExPDF = async (appNo: any) => {
  const queryUrl = `/api/fedEx/${appNo}`;
  return await get(apiGateway, queryUrl, true, fedExCookie);
};

export const getAllFedExLabels = async (appNo: any) => {
  const queryUrl = `/api/FedEx/AllFedExLabels/${appNo}`;
  return await get(apiGateway, queryUrl, true, fedExCookie);
};

export const getFundingUpdateData = async (id: any) => {
  const queryUrl = `/api/loanapp/funding/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getRehashData = async (appNo: any) => {
  const queryUrl = `/api/loanapp/Rehash/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const getRehashDataNoReturn = async (appNo: any) => {
  const queryUrl = `/api/loanapp/Rehash/${appNo}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const saveRehash = async (model: any) => {
  const queryUrl = `/api/loanapp/Rehash`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const revertRehash = async (model: any) => {
  const queryUrl = `/api/loanapp/revertrehash`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const saveDealerComments = async (model: any) => {
  const queryUrl = `/api/dealer/dealer/dealercomments`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    true,
    dealerCookie
  );
};

export const getFundedPopupDetails = async (appNo: number) => {
  const queryUrl = `/api/loanapp/getFundedPopup/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getDealerCommentsByAppNo = async (appNo: number) => {
  const queryUrl = `/api/dealer/dealer/dealercomments/${appNo}`;
  return await get(apiGateway, queryUrl, false, dealerCookie);
};

export const updateDealerComments = async (appNo: number) => {
  const queryUrl = `/api/dealer/dealer/updateDealerComments/${appNo}`;
  return await post(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    false,
    dealerCookie
  );
};

export const saveVehicleInfo = async (model: any) => {
  const queryUrl = `/api/loanapp/vehicleinfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveCustomerInfo = async (model: any) => {
  const queryUrl = `/api/loanapp/customerinfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getCustInfo = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/getcustomerinfo/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getVehInfo = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/getvehicleinfo/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getConfirmContract = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/contract/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const createContract = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/contract/create/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const oldCreateContract = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/contract/oldcreate/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const createDocuSign = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/contract/docusign/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const saveErrorLog = async (model: any) => {
  const queryUrl = `/api/taxonomy/SaveErrorInfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    taxonomyCookie
  );
};

export const savePackageUploadFile = async (model: any) => {
  const queryUrl = "/api/loanapp/savepackageuploadfile";
  return await uploadFileNoReturn(apiGateway, queryUrl, model, loanCookie);
};

export const getPackageUploadFiles = async (
  appNo: number,
  folderName: string
) => {
  const queryUrl = `/api/loanapp/getpackageuploadfiles/${appNo}/${folderName}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const submitPackageUpload = async (model: any) => {
  const queryUrl = `/api/loanapp/packageupload`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getAulRates = async (appNo: any) => {
  const queryUrl = `/api/loanapp/aulrates/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const updateUnreadComments = async (
  appNo: Number,
  messageFrom: string
) => {
  const queryUrl = `/api/loanapp/updateunreadcomments/${appNo}/true/${messageFrom}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getContractTags = async (stateCode: string, loanapp: number) => {
  const queryUrl = `/api/loanapp/contract/contracttags/${stateCode}/${loanapp}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const getContractPopupTags = async (
  stateCode: string,
  loanapp: number,
  popupType: number
) => {
  const queryUrl = `/api/loanapp/contract/contractpopuptags/${stateCode}/${loanapp}/${popupType}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateTags = async (appNo: number, model: any) => {
  const queryUrl = `/api/loanapp/contract/updatecontracttags/${appNo}`;
  return await carletonPut(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getVehInfoFromGov = async (vinNo: any) => {
  const queryUrl = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vinNo}?format=json`;
  return await get3rdParty(queryUrl);
};

export const updateBuyerEmails = async (model: any) => {
  const queryUrl = `/api/loanapp/contract/updatebuyeremails`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateContractEmails = async (model: any) => {
  const queryUrl = `/api/loanapp/contract/updatecontractemails`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getContractAppDetails = async (appNo: any) => {
  const queryUrl = `/api/loanapp/getContractAppDetails/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const updateContractStatus = async (model: any) => {
  const queryUrl = `/api/loanapp/updatecontractstatus`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateContractType = async (model: any) => {
  const queryUrl = `/api/loanapp/updatecontracttype`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getApptEmails = async (appNo: Number) => {
  const queryUrl = `/api/loanapp/contract/getapplicantemails/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const cancelContract = async (model: any) => {
  const queryUrl = `/api/loanapp/contract/cancelcontract`;
  if (model.contractType === contractTypes.Direct) {
    return await postWithCustMesg(
      apiGateway,
      queryUrl,
      model,
      enumContentTypes.json,
      true,
      loanCookie,
      model.eSignStatus === eSignStatusCompInPrgEnum.Complete
        ? DataSubmittedSuccessfullyText
        : "Your cancellation request has been received and is currently being processed."
    );
  } else {
    return await post(
      apiGateway,
      queryUrl,
      model,
      enumContentTypes.json,
      true,
      loanCookie
    );
  }
};

export const showContractPdf = async (appNo: number, contractType: string) => {
  const queryUrl = `/api/loanapp/contract/showcontractpdf/${appNo}/${contractType}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getCPIPremiumAmount = async (
  stateCode: string,
  amountFinanced: number,
  cpiTerm: number
) => {
  const queryUrl = `/api/loanapp/cpipremiumamount/${stateCode}/${amountFinanced}/${cpiTerm}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateLoanSource = async (model: any) => {
  const queryUrl = `/api/loanapp/updateloansource`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getDealerSignDetails = async (loanapp: number) => {
  const queryUrl = `/api/loanapp/contract/dealersigndetails/${loanapp}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateDealerSignDetails = async (model: any) => {
  const queryUrl = `/api/loanapp/contract/updatedealersigndetails`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};
